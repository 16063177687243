import React from "react";
import PropTypes from "prop-types";

const BlogTags = (props) => {
    let secondTag = props.date;
    if (props.subtitle) {
        secondTag = `${props.subtitle} | ${secondTag}`;
    }
    return (
        <div className="tags has-addons">
            <span className="tag is-info">{secondTag}</span>
            <span className="tag is-success">{props.readingTime}</span>
        </div>
    );
};

BlogTags.propTypes = {
    date: PropTypes.string,
    subtitle: PropTypes.string,
    readingTime: PropTypes.string,
};

export default BlogTags;
