import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PropTypes from "prop-types";
import BlogTags from "../components/BlogTags";
import "github-markdown-css/github-markdown.css";

export default function Template({ data }) {
    const { markdownRemark } = data;
    const { fields, frontmatter, html } = markdownRemark;
    return (
        <Layout>
            <SEO title="Home" />
            <div className="blog-container">
                <BlogTags
                    date={frontmatter.date}
                    subtitle={frontmatter.subtitle}
                    readingTime={fields.readingTime.text}
                />
                {frontmatter.author && <div>by {frontmatter.author}</div>}
                <div className="title">{frontmatter.title}</div>
                <div className="subtitle">{frontmatter.description}</div>
                <div className="markdown-body" dangerouslySetInnerHTML={{ __html: html }} />
            </div>
        </Layout>
    );
}

Template.propTypes = {
    data: PropTypes.object,
};

export const pageQuery = graphql`
    query ($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                slug
                title
                description
                author
                subtitle
            }
            fields {
                readingTime {
                    text
                }
            }
        }
    }
`;
